import React, { useEffect, useState, useRef } from 'react';

import '../styles/speech.scss';

const useSpeechSynthesis = () => {
  const [voices, setVoices] = useState([]);
  const synth = useRef();

  const updateVoices = () => {
    setVoices(synth.current.getVoices());
  };

  const speak = (text, voice, pitch = 1, rate = 1) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = voice;
    utterance.pitch = pitch;
    utterance.rate = rate;
    synth.current.speak(utterance);
  };

  useEffect(() => {
    if (typeof window !== 'object' || !window.speechSynthesis) {
      return '';
    } else {
      synth.current = window.speechSynthesis;
      synth.current.onvoiceschanged = updateVoices;
      updateVoices();
      return () => {
        synth.current.onvoiceschanged = null;
      };
    }
  }, []);

  return [voices, speak];
};

const Speech = () => {
  const [voices, speak] = useSpeechSynthesis();
  const [text, setText] = useState('');
  const [currentVoice, setCurrentVoice] = useState();

  useEffect(() => {
    if (!currentVoice) {
      setCurrentVoice(voices.filter((v) => v.default)[0] || voices[0]);
    }
  }, [voices, currentVoice]);

  const onClick = () => {
    speak(text, currentVoice);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    setCurrentVoice(voices.filter((v) => v.name === e.target.value)[0]);
  };

  return (
    <div id="speech">
      <div className="controls">
        <button onClick={onClick}>Play</button>
      </div>
      <textarea type="text" rows="4" className="text" onChange={handleTextChange} value={text} />
      <select
        className="voices"
        value={currentVoice ? currentVoice.name : ''}
        onBlur={handleVoiceChange}
        onChange={handleVoiceChange}
      >
        {voices.map((v) => (
          <option key={v.name} value={v.name}>{`${v.name}`}</option>
        ))}
      </select>
      <div className="controls">
        <button onClick={onClick}>Play</button>
      </div>
    </div>
  );
};

export default Speech;
